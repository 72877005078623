@import '~@ag-grid-community/styles/ag-grid.css';
@import '~@ag-grid-community/styles/ag-theme-alpine.css';

  .ag-theme-alpine .ag-header-row {
    height: 44px;
  }

  .ag-theme-alpine .ag-row {
    height: 36px;
  }
  
  .ag-theme-alpine .ag-cell {
    /* display:flex; */
    align-items: center;
    line-height: normal;
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: middle;
  }

  .header-small-cell {
    padding-right: 2px !important;
    padding-left: 10px !important;
    text-overflow: clip !important;
  }
  
  .header-small-cell i.icon {
    font-size: 10px !important;
    width: 10px !important;
    height: auto !important;
    margin: 2px 0px 0px 0px !important;
  }
  
  .ag-theme-alpine .ag-cell i.icon {
    width: 10px !important;
    height: auto !important;
    margin: 4px 0px 0px 0px !important;
  }

  .header-small-cell .ag-header-icon {
    margin: 0px !important;
  }
  
  .header-small-cell .ag-header-cell-text {
    overflow: visible;
  }
  
  .ag-header-cell-sortable {
    cursor: default !important;
  }
  
  .ag-header-cell-sortable .ag-cell-label-container {
    cursor: pointer !important;
  }
  
  .ag-paging-panel {
    justify-content: flex-start !important;
  }

  .ag-tooltip {
    background-color: floralwhite !important;
    border-style: solid !important;
    border-width: 1px !important; 
    border-color: grey !important;
  }
  
  .cell-wrap-text {
    white-space: normal !important;
  }

  .ag-watermark {
    display: none;
  }
