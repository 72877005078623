i.logo-icon {
  background-image: url(images/logo.svg);
  background-repeat: no-repeat;
  display: inline-flex;
  vertical-align: bottom;
  width: 32px;
  height: 31px;
  margin-right: 0.5rem;
  margin-left: -32px;
 }

.app-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  padding: 0px 2px 4px 2px;
  min-height: 40px;
}

.app-toolbar div.ui.button.ui.dropdown {
  padding: 0.6em 1.5em 0.55em;
}

.app-page-header {
  display: inline-flex;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 1em;
}

.app-page-header > i.icon {
  font-size: 0.75em;
}

.app-toolbar-btn:not(:hover) {
  box-shadow: none !important;
}

.btn-level-up {
  margin-left: -4px !important;
}

.ui.primary.button {
  background-color: #2AA65C !important;
}

.error_msg {
  background-color: #fcf7b6;
  color: red;
}

.warn_msg {
  background-color: #fcf7b6;
  color: red;
}

.app-tasklist-grid > div > .ag-root-wrapper {
  border-radius: 5px;
}


.toolbar {
  margin-bottom: 4px;
}

.with-bottom-padding {
  padding-bottom: 10px;
}

.btn-link {
  font-weight: 400;
  color: #155cb5;
  background-color: transparent;
  border-style: none;
  padding: 0 2px 0 2px;
  cursor: pointer;
}

.btn-link[disabled] {
  color: #808080;
  cursor : not-allowed;
}

span.inline-divider {
  margin: 0 0.5em;
  color: #808080;
}

span.inline-divider::after {
  content: '|';
}

div.ui.inverted.popup {
  background: rgba(64,64,64);
}

div.ui.inverted.popup:before {
  background: rgba(64,64,64) !important;
}

.ui.dropdown .menu>.header {
  color: #808080;
}

.ui.dropdown .menu {
  z-index: 1001;
}

div.ui.input > input[type=number] {
  padding-right: 4px;
}

div.ui.selection.dropdown {
  min-height: 2em;
}