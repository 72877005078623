.dev-toolbar {
  background-color: rgb(254,244,203);
  font-size: smaller;
  padding: 0.5em;
  margin-left: -0.5em;
  margin-right: -0.5em;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.2em;
}

.dev-toolbar.disabled {
  color:rgb(120, 120, 120);
}
.dev-toolbar>.header {
  color: rgb(96, 96, 96);
  font-family: 'Arial Narrow';
  font-weight: bold;
  padding: 0.2em;
  margin-right: 2em;
}
.dev-toolbar>.header::after {
  content: 'DEV';
}

.dev-toolbar label.checkbox {
  display: inline-block;
  margin-right: 0.2em;
}

.dev-toolbar label.checkbox input {
  vertical-align: text-bottom;
  margin-right: 0.2em;
  transform: scale(0.8);;
}

.dev-toolbar .text {
  display: inline-block;
  padding: 0 0.5em 0 0.5em;
  min-width: 40em;
  margin-right: 0.2em;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, .87);
  border: 1px solid rgba(34, 36, 38, .15);
  border-radius: 3px;
  transition: box-shadow .1s ease, border-color .1s ease;
}

.dev-toolbar:not(.disabled) .text:hover {
  border-color: rgba(34, 36, 38, .25);
}

.dev-toolbar:not(.disabled) .text.focus {
  border-color: #85b7d9;
}

.dev-toolbar .text.placeholder {
  color: rgb(112, 112, 112);
}

.dev-toolbar.disabled .text.placeholder {
  color: rgb(200, 200, 200);
}

.dev-toolbar .ui.compact.icon.button, .ui.compact.icon.buttons .button {
  padding: 0.5em;
}

.dev-toolbar-url-editor {
  min-width: 40em;
}

/* dark mode */
@media (prefers-color-scheme: dark) {

  .dev-toolbar {
    background-color: rgb(32,32,32);
    color: rgb(200, 200, 200);
  }

  .dev-toolbar.disabled {
    color:rgb(120, 120, 120);
  }
  .dev-toolbar>.header {
    color: rgb(200, 200, 200);
  }
  .dev-toolbar>.header::after {
    content: 'DEV';
  }

  .dev-toolbar label.checkbox {
    color: rgb(200, 200, 200);
  }

  .dev-toolbar label.checkbox input {
    appearance: auto;
  }

  .dev-toolbar .text {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, .87);
    border-color: rgba(34, 36, 38, .15);
  }

  .dev-toolbar:not(.disabled) .text:hover {
    border-color: rgba(34, 36, 38, .25);
  }

  .dev-toolbar:not(.disabled) .text.focus {
    border-color: #85b7d9;
  }

  .dev-toolbar .text.placeholder {
    color: rgb(112, 112, 112);
  }

  .dev-toolbar.disabled .text.placeholder {
    color: rgb(200, 200, 200);
  }
}
