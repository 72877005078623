.date-range-picker {
  min-width: 38em !important;
}

.date-range-picker > .header {
  margin-bottom: 1em;
}

.date-range-picker .column > div.field {
  display: inline-block;
}

.date-range-picker .column > .btn-link {
  font-size: 1.1em;
}