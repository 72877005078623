
.dev-mode-settings.ui.modal>.header{
  padding: 1rem;
  background-color: rgb(255,246,247);
  line-height: normal;
  color: rgb(0, 0, 0, 0.6);
}



.dev-mode-settings.ui.modal>.content{
  padding: 1rem;
  background-color: rgb(255,246,247);
}


.dev-mode-settings.ui.modal>.actions{
  padding: 1rem;
  background-color: rgb(255,246,247);
}

.dev-mode-settings.ui.modal>.actions>.button{
  min-width: 6rem;
}
