.unviewed_label {
  color: lightgray;
  font-weight: bolder;
  font-size: larger;
  visibility: hidden;
}
.unviewed_group .unviewed_label {
  visibility: visible !important;
  text-decoration: blink !important;
  animation: blinker 0.5s linear infinite alternate;
}

@keyframes blinker {
  to {
    color: #db2828;
  }
}

.unviewed_task .unviewed_label {
  visibility: visible !important;
}

.priority_label {
  color: #db2828;
  font-weight: bolder;
  font-size: larger;
  visibility: hidden;
}

.priority_label_tag {
  margin-top: 1em !important;
}

.prioritized .priority_label {
  visibility: visible !important;
}

.busy_label {
  color: #db2828;
  text-overflow: clip !important;
}

.tag-label-group {
  padding-top: 6px;
}

.expired_task {
  color: red
}

.ag-header-cell-label .ag-header-icon .ag-sort-order {
  display: block
}

.ag-theme-alpine {
  --ag-row-height: calc(var(--ag-grid-size) * 4);
}

.processing-bar {
  height: 60px;
  padding-top: 10px;
  width: 100%;
}
