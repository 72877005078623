.form-page-container {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 1em;
}

.task-info-panel {
  display: flex;
  justify-content: space-between;
}

.task-info-panel div:not(:first-child):not(:last-child) {
  text-align: center;
}

.task-info-panel div:last-child {
  text-align: right;
}

.task-info-label {
  color: #808080;
}

/*.assignee-control {
  float: right;
}

div:has(.assignee-control) {
  min-height: 2ex;
}*/

.form-container {
  min-height: 10rem;
}

.disabled-form {
  position: relative;
}

.disabled-form::before {
  display: block;
  content: ' ';
  background-color: rgba(238,238,238,0.33);
  position: absolute;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  cursor: not-allowed;
  z-index: 1000;
}
