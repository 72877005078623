
.menu-panel {
  padding-right: 4px !important;
  width: 14em !important;
}
.content-panel {
  padding-left: 4px !important;
  width: calc(95% - 14em) !important;
}
.diagram-panel {
  padding-left: 4px !important;
  width: calc(100% - 14em) !important;
  height: calc(100vh - 90px);
}

.data-sections > div.title {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4) !important;
  transition: color 0.2s ease;
}

.data-sections > div.active.title {
  color: rgba(0, 0, 0, 0.95) !important;
}
  
.data-sections > div.title:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.table-label {
  background-color: rgba(0, 0, 0, 0.03);
  text-align: right !important;
  font-weight: 700;
}

.table-data {
  width: 70%;
}

.var-value {
  display: flex;
}
.var-value > span {
  display: inline-block;
  max-width: calc(100% - 2em);
}
.var-value > span.object-text {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.var-value > .ui.icon.button {
  padding: 0.4em;
}

.object-source {
  white-space: pre-wrap;
}
.object-source .string { color: #0451a5; }
.object-source .number { color: #098658; }
.object-source .boolean { color: #0451a5; font-weight: bolder;}
.object-source .null { color: #0451a5; font-weight: bolder; }
.object-source .key { color: #a31515; }
