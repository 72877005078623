
.copy-btn {
  visibility: hidden;
  color: transparent;
}
.copy-btn:hover {
  color: #00b5adff !important;
}
*:hover > .copy-btn {
  visibility: visible;
  color: #3aa19cb0;
  transition: color 500ms;
}