.bpm-app body{
  background-color: #FAFAFB;
}

.page-container {
  padding-left: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
}

.ui-alerts.top-right {
  top: 2rem;
  right: 20px;
}

.ui.page.dimmer {
  background-color: rgba(0,0,0,.70) !important;
}

.ui.menu .item.logo {
  align-items: end;
}

.ui.menu .item.logo > div {
  color: goldenrod;
  font-size: smaller;
  margin-bottom: -4px;
}

.ui.menu .item.logo > i
{
  margin-top: -6px;
  margin-bottom: -6px;
  width: 32px;
  height: 31px;
  background-image: url(images/logo.svg);
}

.ui.dropdown .menu>.header>.user-id {
  text-transform: none;
  font-size: small;
}

.ui.dropdown .menu>.header>.warning {
  text-transform: none;
  font-size: small;
  color: rgba(255, 0, 0, 0.8);
}