.ui.icon.input>i.icon.right {
  right: 0 !important;
  left: auto !important;
}

.ui.form .field>label.light {
  font-weight: normal;
  text-align: left;
}

.ui.form .field.reset-password {
  text-align: right;
}