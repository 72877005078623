.history-table td {
  border-left: none !important;
}
.history-table td:first-child {
    min-width: 6.5em;
}

.history-del-btn {
  box-shadow: none !important;
  background-color: transparent !important;
}

.history-del-btn:hover {
  box-shadow: none !important;
  background-color: transparent !important;
}
  
.history-del-btn i {
  color: rgba(191, 0, 0, 0.75) !important;
}
  
.history-del-btn:hover i {
  color: rgb(191, 0, 0) !important;
}

.history-item .field {
  display: inline-block;
  margin-right: 1em;
}

.history-item .field .label {
  color: brown;
}