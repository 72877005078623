
.height-full {
  height: 100%;
}

.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bpmn-diagram-container {
  height: 100%;
  width: 100%;
}

.task-active:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: #abfd8e !important; /* color elements as green */
  stroke: #45dc0f !important; /* color elements as green */
}

.task-finished:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: #bbdefb !important; /* color elements as green */
  stroke: #1e88e5 !important; /* color elements as green */
}

.task-incident:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: #ff9999 !important; /* color elements as green */
  stroke: #ff3939 !important; /* color elements as green */
}

.task-canceled:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: #bbdefb !important; /* color elements as green */
  stroke: #ff3939 !important; /* color elements as green */
}

.task-clean:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: white !important; /* color elements as green */
  stroke: black !important; /* color elements as green */
}

.djs-overlay .ui.bottom.popup {
  min-width: 36em;
  max-width: 36em;
}
.djs-overlay .ui.bottom.popup.right {
  margin-right: -1em;
}
.djs-overlay .ui.bottom.popup.left {
  margin-right: -34em;
}

.element-info .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1em;
}

.element-info .ui.header .icon:only-child{
  display: block;
  margin-right: -0.4em;
  margin-top: -0.1em;
  padding: 0;
  background-color: transparent;
}

.element-info .header-name {
  font-size: 1.1em;
  color: #404040;
  padding-right: 1em;
  padding-bottom: 0.2em;
}

.element-info .header-id {
  font-size: 0.9em;
  color: #707070;
}