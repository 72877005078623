.unviewed_label {
  color: lightgray;
  font-weight: bolder;
  font-size: larger;
  display: none;
}
.unviewed_group .unviewed_label {
  /*display: inline !important;*/
  text-decoration: blink !important;
  animation: blinker 0.5s linear infinite alternate;
}

@keyframes blinker {
  to { color: red; }
}

/*.unviewed_task .unviewed_label {
  display: inline !important;
}*/

.priority_label {
  color: red;
  font-weight: bolder;
  font-size: larger;
  display: none;
}
.prioritized .priority_label {
  display: inline !important;
}

.expired_task {
  color: red;
}
.not_expired_task {
  color: green;
}